<template>
  <div class="division"></div>
</template>

<script>
export default {
  name: "division",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped>
.division {
  background: #ec1e14;
  width: 100%;
  height: 22px;
  border-bottom: 11px #efc562 solid;
}
</style>
