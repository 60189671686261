<template>
  <div>
    <div id="container"></div>
    <!-- {{ data }} -->
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import store from "@/store/store.js";
export default {
  name: "container",
  props: ["data"],
  data() {
    return {
      map: null,
      center: store.state.center,
      //   center: this.data,
    };
  },

  mounted() {
    AMapLoader.load({
      key: "f18ffbe152872c4599eb61b8a4ae7036",
      version: "2.0",
      plugins: ["AMap.OverView"],
    }).then(() => {
      this.initMap();
      this.$parent.$on("event-from-parent", this.initMap);
    });
  },

  methods: {
    initMap() {
      //   this.center = this.data;
      //   console.log(store.state.center);
      //   console.log("1");
      // 创建地图实例
      const map = new AMap.Map("container", {
        center: store.state.center,
        zoom: 16,
        scrollWheel: false,
        dragEnable: false,
      });
      //   console.log("2");
      const pngMarker = new AMap.Marker({
        position: store.state.center,
        content: `<div><img src="http://bizpub.oss-cn-hangzhou.aliyuncs.com/编组 7.png" alt="png-marker"></div>`,
        offset: new AMap.Pixel(-100, -10), // 设置图片偏移量
      });
      map.add(pngMarker);
      // initMap() {
      //   AMapLoader.load({
      //     key: "f18ffbe152872c4599eb61b8a4ae7036", // 申请好的Web端开发者Key，首次调用 load 时必填
      //     version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      //     plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      //   })
      //     .then((AMap) => {
      //       this.map = new AMap.Map("container", {
      //         //设置地图容器id
      //         viewMode: "2D", //是否为3D地图模式
      //         zoom: 16, //初始化地图级别
      //         center: this.center, //初始化地图中心点位置
      //       });
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //     });
    },
  },
};
</script>

<style scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 400px;
}
</style>
