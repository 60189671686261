import request from '@/util/request.js'
//获取新闻种类
export function userLogin(params) {
    return request({
        url: `/api/admin/login`,
        method: 'post',
        params
    })
}
// ClassifiedNews
export function ClassifiedNews(id) {
    return request({
        url: `/api/admin/posts?status=PUBLISHED&page=0&size=6&categoryld=${id}`,
        method: 'get',
    })
}

