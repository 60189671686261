import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Index',
            component: () => import('@/view/Index.vue'),
        },
        {
            path: '/industrial_development',
            name: 'industrialDevelopment',
            component: () => import('@/view/3_industrial_development/industrial_development.vue')
        },
        {
            path: '/press_center',
            name: 'press_center',
            component: () => import('@/view/4_press_center/press_center.vue'),
        },
        {
            path: '/press_center_DetailPage',
            name: 'press_center_DetailPage',
            component: () => import('@/view/4_press_center/DetailPage.vue'),
        },
        {
            path: '/contact_us',
            name: 'contact_us',
            component: () => import('@/view/8_contact_us/contact_us.vue')
        },
        {
            path: '/scanCode',
            name: 'scanCode',
            component: () => import('@/view/scan/index.vue')
        },
    ]
})
export default router
