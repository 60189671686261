import Vue from 'vue'
import Vuex from 'vuex'

//1.安装插件
Vue.use(Vuex)

//2.创建对象
export default new Vuex.Store({
  state: {
    center: [120.503875, 30.091548],
    Mcenter: [120.501945, 30.090583],
    access_token: ''
  },
  mutations: {
    // 设置定位位置
    centerChange(state, n) {
      switch (n) {
        case 1:
          state.center = [120.503618, 30.091776];
          // this.$emit("event-from-parent", state.center);
          // console.log(store.state.center);
          break;
        case 2:
          state.center = [121.842926, 30.924729];
          // console.log(this.center);
          // console.log(store.state.center);
          // this.$emit("event-from-parent", state.center);
          break;
        case 3:
          //   window.scrollTo(0, 1868.1);
          break;
        case 4:
          //   window.scrollTo(0, 2444.3);
          break;
        case 5:
          //   window.scrollTo(0, 3092.1);
          break;
        default:
          break;
      }
    },
    McenterChange(state, n) {
      switch (n) {
        case 1:
          state.Mcenter = [120.501945, 30.090583];
          // this.$emit("event-from-parent", state.center);
          // console.log(store.state.center);
          break;
        case 2:
          state.Mcenter = [121.842926, 30.924729];
          // console.log(this.center);
          // console.log(store.state.center);
          // this.$emit("event-from-parent", state.center);
          break;
        case 3:
          //   window.scrollTo(0, 1868.1);
          break;
        case 4:
          //   window.scrollTo(0, 2444.3);
          break;
        case 5:
          //   window.scrollTo(0, 3092.1);
          break;
        default:
          break;
      }
    },
    setAccess_token(state, n) {
      state.access_token = n
    },
  },
  actions: {

  },
  getters: {
    getAccess_token(state) {
      return state.access_token
    }

  },
  modules: {

  }
})
//3.导出使用
