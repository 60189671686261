<template>
  <div class="centerS"></div>
</template>

<script>
export default {
  name: "centerS",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped>
.centerS {
  margin: 0 auto;
  width: 60px;
  height: 2px;
  margin-top: 25px;
  background-color: red;
  border-bottom: 1px #efc562 solid;
}
</style>
