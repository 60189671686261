<template>
  <div id="app">
    <div class="newHome">
      <div class="header">
        <div class="header_logo" @click="logoClick">
          <img src="../static/newImage/logo.jpg" height="60px" />
        </div>
        <div class="header_search">
          <img src="../static/newImage/home_phone.png" width="16px" />
          <div class="header-phone-num">400-185-3168</div>
        </div>
      </div>
      <div class="home_title">
        <div class="home_title_list">
          <div class="home_title_list_item" v-for="(item, index) in titleList" :key="item.name + 'title'"
            @click="toPath(item.path, item.id)">
            {{ item.name }} <span v-show="index != 8">|</span><span v-show="index == 8"
              style="color: transparent;">|</span>
          </div>
        </div>
      </div>
      <router-view />
      <div class="foot">
        <div class="foot_top_box">
          <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
            <div class="foot_center_box">
              <ul v-for="item in list" :key="item.id" style="margin-right: 8px" @click="footJunp">
                <div style="
                    height: 26px;
                    color: #FFFFFF;
                    cursor: pointer;
                    font-size: 14px;
                    margin-bottom: 8px;
                  ">
                  {{ item.head }}
                </div>
                <li>{{ item.content.content1 }}</li>
                <li>{{ item.content.content2 }}</li>
                <li>{{ item.content.content3 }}</li>
                <li>{{ item.content.content4 }}</li>
              </ul>
            </div>
            <div class="foot_center_left_box">
              <div style="text-align: center;margin-bottom: 30px;">
                <img src="../static/newImage/logo_fff.png" />
              </div>
              <div style="color: #fff; font-size: 12px;text-align: center;">
                全国统一服务热线
              </div>
              <div style="color: #E9C259; font-size: 16px; padding: 8px 0;text-align: center;">
                400&nbsp;185&nbsp;3168
              </div>
            </div>
          </div>
        </div>
        <div class="foot_center_bottom">
          <div class="foot_center_bottom_div">
            <div style="color: #979daa; font-size: 12px; text-align: left">
              <div style="margin: 0 auto">
                <a target="_blank" href="https://beian.miit.gov.cn" style="
                      display: inline-block;
                      text-decoration: none;
                      height: 20px;
                      line-height: 20px;
                    "><img src="../static/newImage/beian.png" style="float: left" />
                  <p style="
                        float: left;
                        height: 20px;
                        line-height: 20px;
                        margin: 0px 0px 0px 5px;
                        color: #939393;
                      ">
                    浙ICP备2023019923号-1 &nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                </a><a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33060302001196"
                  style="
                      display: inline-block;
                      text-decoration: none;
                      height: 20px;
                      line-height: 20px;
                    ">
                  <p style="
                        float: left;
                        height: 20px;
                        line-height: 20px;
                        margin: 0px 0px 0px 5px;
                        color: #939393;
                      ">
                    浙公网安备 33060302001196号
                  </p>
                </a>
                <p
                  style="color: #fafafb; font-size: 12px; text-align: center;display: inline-block;margin: 0;float: right;">
                  中峪控股集团
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Toast } from "vant";
export default {
  name: "App",
  data() {
    return {
      activeIndex: true,
      scroll: "",
      userAgent: "", //获取当前设备类型
      isMobile: false, //控制是否为手机端
      deviceWidth: null, //设备宽度
      timer: "",
      seen: true,
      icon_down: true,
      currentMenu: "首页",
      home_icon: false,
      nav_id: "1",
      btnDisplay: "none",
      list: [
        {
          head: "关于我们",
          content: {
            content1: "企业介绍",
            content3: "企业文化",
            content4: "联系我们",
          },
        },
        {
          head: "控股产业",
          content: {
            content1: "制药板块",
            content2: "中峪商贸",
            content3: "中峪学校",
            content4: "麒典信息科技",
          },
        },
        {
          head: "控股产业",
          content: {
            content1: "道地药材",
            content2: "中峪数交",
            content3: "中峪交易市场",
            content4: "金生投资",
          },
        },
        {
          head: "新闻中心",
          content: {
            content1: "集团要闻",
            content2: "产业动态",
            content3: "媒体报道",
            content4: "政府支持",
          },
        },
        {
          head: "新闻中心",
          content: {
            content1: "社会贡献",
            content2: "热点专题",
            content3: "集团新闻",
          },
        },
      ],
      headerlist: [
        {
          id: "1",
          text: "首页",
        },
        {
          id: "3",
          text: "产业发展",
        },
        {
          id: "4",
          text: "新闻中心",
        },
        {
          id: "5",
          text: "关于我们",
        },
      ],
      moreId: 1,
      journalismId: 10,
      showOldHome: false,
      first: [],
      column: [],
      growR: false,
      growL: false,
      show: false, //控制展开
      value: "", //搜索框内容
      yearLength: 150,
      startMoreX: 0, //开始移动的坐标
      translateX: 0, //内容的偏移量
      moveX: 0, //记录移动位置
      showContent: false, //控制+-按钮
      showContentone: false,
      titleList: [
        {
          name: "网站首页",
          path: "/",
          mpath: "MobilePage",
        },
        {
          name: "控股产业",
          path: "/industrial_development",
          mpath: "/industry",
        },
        {
          name: "集团要闻",
          path: "/press_center",
          mpath: "/journalism",
          id: 17
        },
        {
          name: "产业动态",
          path: "/press_center",
          mpath: "/journalism",
          id: 16
        },
        {
          name: "媒体报道",
          path: "/press_center",
          mpath: "/journalism",
          id: 15
        },
        {
          name: "政府支持",
          path: "/press_center",
          mpath: "/journalism",
          id: 27
        },
        {
          name: "社会贡献",
          path: "/press_center",
          mpath: "/journalism",
          id: 28
        },
        {
          name: "热点专题",
          path: "/press_center",
          mpath: "/journalism",
          id: 24
        },
        {
          name: "企业介绍",
          path: "/contact_us",
          mpath: "/AboutUs",
        },
      ],
    };
  },

  methods: {
    toPath(path, id) {
      this.$router.push({ path, query: { websiteCategoryId: id } });
      window.scrollTo(0, 0);
    },
    backTop() {
      //当点击标签的时候,使用animate在0毫秒的时间内,滚到顶部
      this.timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        if (osTop === 0) {
          clearInterval(this.timer);
        }
      }, 0);
    },
    //点击跳转到联系我们
    consult() {
      const dingwei = true;
      if (this.$route.path == "/contact_us") {
        window.scrollTo(0, 4300);
      } else {
        this.$router.push({ name: "contact_us", params: { dingwei } });
      }
    },
    Mconsult() {
      const dingwei = true;
      if (this.$route.path == "/AboutUs") {
        window.scrollTo(0, 2600);
      } else {
        this.$router.push({ name: "AboutUs", params: { dingwei } });
      }
    },
    menuSeen() {
      let screenHeight = document.body.clientWidth;
      // 屏幕尺寸
      if (screenHeight > 768) {
        this.seen = true;
      } else {
        this.seen = false;
      }
      if (screenHeight > 1400) {
        this.icon_down = true;
      } else {
        this.icon_down = false;
      }
    },
    logoClick() {
      if (this.$route.path == '' || this.$route.path == '/') {
        location.reload();
      } else {
        this.$router.push("/");
      }
      this.nav_click(1);
      this.home_icon = true;
      this.currentMenu = "首页";
    },
    nav_click(x) {
      this.nav_id = x;
      switch (x) {
        case "1":
          this.$router.push("/");
          this.home_icon = true;
          break;
        case "2":
          this.$router.push("/enterprisProfile");
          this.home_icon = false;
          localStorage.setItem("nav_id", this.nav_id);
          break;
        case "3":
          this.$router.push("/industrial_development");
          this.home_icon = false;
          localStorage.setItem("nav_id", this.nav_id);
          break;
        case "4":
          this.$router.push("/pressAll");
          this.home_icon = false;
          localStorage.setItem("press_center_id", 1);
          localStorage.setItem("nav_id", this.nav_id);
          break;
        case "5":
          this.$router.push("/contact_us");
          this.home_icon = false;
          localStorage.setItem("nav_id", this.nav_id);
          break;
        default:
          break;
      }
    },
    footJunp(e) {
      switch (e.target.innerText) {
        case "关于我们":
          this.$router.push({ name: "contact_us" });
          window.scrollTo(0, 0);
          break;
        case "企业介绍":
          this.$router.push({ name: "contact_us" });
          window.scrollTo(0, 0);
          break;
        case "企业文化":
          this.$router.push({ name: "contact_us" });
          window.scrollTo(0, 2250);
          break;
        case "联系我们":
          this.consult();
          break;
        case "控股产业":
          this.$router.push({ path: `/industrial_development` });
          window.scrollTo(0, 0);
          break;
        case "制药板块":
          this.$router.push({ path: `/industrial_development`, query: { id: 9 } });
          window.scrollTo(0, 0);
          break;
        case "养老板块":
          this.$router.push({ path: `/industrial_development`, query: { id: 1 } });
          window.scrollTo(0, 0);
          break;
        case "中峪商贸":
          this.$router.push({ path: `/industrial_development`, query: { id: 5 } });
          window.scrollTo(0, 0);
          break;
        case "中峪学校":
          this.$router.push({ path: `/industrial_development`, query: { id: 3 } });
          window.scrollTo(0, 0);
          break;
        case "麒典信息科技":
          window.open("https://www.zoneyu.com.cn/");
          window.scrollTo(0, 0);
          break;
        case "道地药材":
          window.open("https://www.zoneyu.cc/");
          window.scrollTo(0, 0);
          break;
        case "中峪数交":
          window.open("https://sj.zoneyu.net/");
          window.scrollTo(0, 0);
          break;
        case "中峪交易市场":
          this.$router.push({ path: `/industrial_development`, query: { id: 1 } });
          window.scrollTo(0, 0);
          break;
        case "金生投资":
          this.$router.push({ path: `/industrial_development`, query: { id: 4 } });
          window.scrollTo(0, 0);
          break;
        case "医院板块":
          this.$router.push({ path: `/industrial_development`, query: { id: 1 } });
          window.scrollTo(0, 0);
          break;
        case "新闻中心":
          this.$router.push({ path: '/press_center', query: { websiteCategoryId: 17 } });
          window.scrollTo(0, 0);
          break;
        case "集团要闻":
          this.$router.push({ path: '/press_center', query: { websiteCategoryId: 17 } });
          window.scrollTo(0, 0);
          break;
        case "产业动态":
          this.$router.push({ path: '/press_center', query: { websiteCategoryId: 16 } });
          window.scrollTo(0, 0);
          break;
        case "媒体报道":
          this.$router.push({ path: '/press_center', query: { websiteCategoryId: 15 } });
          window.scrollTo(0, 0);
          break;
        case "政府支持":
          this.$router.push({ path: '/press_center', query: { websiteCategoryId: 27 } });
          window.scrollTo(0, 0);
          break;
        case "社会贡献":
          this.$router.push({ path: '/press_center', query: { websiteCategoryId: 28 } });
          window.scrollTo(0, 0);
          break;
        case "热点专题":
          this.$router.push({ path: '/press_center', query: { websiteCategoryId: 24 } });
          window.scrollTo(0, 0);
          break;
        case "集团新闻":
          this.$router.push({ path: '/press_center', query: { websiteCategoryId: 22 } });
          window.scrollTo(0, 0);
          break;
        default:
          break;
      }
    },
    Btnclik(id) {
      if (id == 8 || id == 10) {
        this.moreId = 1;
      }
      this.moreId = id;
    },
    //成长滚动btn
    growUpL() {
      this.growL = false;
      this.growR = true;
    },
    growUpR() {
      this.growL = true;
      this.growR = false;
    },
    startTouch(e) {
      this.startX = e.touches[0].pageX;
    },
    moveTouch(e) {
      // 左右滚动
      const offsetX = e.touches[0].pageX - this.startX;
      this.moveX = offsetX + this.translateX;
    },
    EndTouch() {
      this.translateX = this.moveX;
      if (this.translateX > 0) {
        this.translateX = 0;
        this.startX = 0;
        this.moveX = 0;
      }
      if (this.translateX < -870) {
        this.translateX = -850;
        this.startX = -850;
        this.moveX = -850;
      }
    },
    // 展开
    showPopup() {
      this.show = true;
    },
    onSearch(val) {
      Toast(val);
    },
    onCancel() {
      Toast("取消");
    },
    mobileJunp(id) {
      this.show = false;
      if (id == 1) {
        this.$router.push("/MobilePage");
      }
      if (id == 2) {
        this.$router.push("/industry");
      }
      if (id == 3) {
        this.$router.push("/journalism");
      }
      if (id == 4) {
        this.$router.push("/AboutUs");
      }
    },
    logo() {
      this.$router.push("/MobilePage");
    },
  },
  created() {
    this.userAgent = navigator.userAgent;
    // 定义手机设备的关键词
    let mobileKeywords = ["Mobile", "Android", "iPhone", "Windows Phone"];

    // 判断是否是手机设备
    this.isMobile = false;
    for (let i = 0; i < mobileKeywords.length; i++) {
      if (this.userAgent.indexOf(mobileKeywords[i]) > -1) {
        this.isMobile = true;
        break;
      }
    }
    if (window.innerWidth < 510) {
      this.isMobile = true;
    }
    if (this.isMobile) {
      localStorage.setItem("device", this.isMobile);
    } else {
      localStorage.setItem("device", this.isMobile);
    }
  },
  mounted() {
    this.menuSeen();
    this.home_icon = true;
    this.nav_id = localStorage.getItem("nav_id");
    if (this.nav_id == 1) {
      this.home_icon = true;
    } else {
      this.home_icon = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.newHome {
  min-width: 1240px;

  .home_title {
    min-width: 1240px;
    background: #e11515;
    color: #fff;
    padding: 0;
    height: 41px;
    line-height: 41px;

    .home_title_list {
      display: flex;
      width: 1240px;
      margin: 0 auto;

      .home_title_list_item {
        display: block;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        padding: 0;
        cursor: pointer;

        span {
          margin-left: 20px;
        }
      }
    }
  }

  .header {
    width: 1170px;
    margin: 0 auto;
    display: flex;
    padding: 10px 50px 10px 20px;
    justify-content: space-between;
    align-items: center;

    .header_logo {
      background: #fff;
      overflow: hidden;
    }

    .header_search {
      display: flex;
      align-items: center;
      background: #F1F1F1;
      border-radius: 28px;
      height: 50px;
      padding: 0 20px;


      .header-phone-num {
        margin-left: 10px;
        color: #565759;
        font-size: 18px;
        font-weight: 500;
        display: inline-block;
      }

      .header_search_name {
        width: 80px;
        font-size: 12px;
      }

      .header_search_btn {
        margin-left: 10px;
        background-color: #c51722;
        border: none;
      }
    }
  }

  .foot {
    padding-top: 16px;
    background: #34476e;
    width: 100%;
    min-width: 1240px;
    background-color: #34476e;

    .foot_top_box {
      width: 1200px;
      min-width: 720px;
      margin: 0 auto;
      padding: 0 20px;
      padding-bottom: 90px;
    }

    .foot_center_box {
      height: 168px;
      display: flex;
      margin-top: 61px;
    }

    .foot_center_left_box {
      margin-top: 61px;
    }

    .foot_center_box>ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .foot_center_box>ul>li {
      color: rgba(255, 255, 255, 0.56);
      cursor: pointer;
      font-size: 12px;
      line-height: 32px;
      margin: 0 90px 0 0;
    }

    .foot_center_bottom {
      width: 1240px;
      margin: 0 auto;
      height: 60px;
    }

    .foot_center_bottom_div {
      border-top: 2px solid rgba(255, 255, 255, 0.16);
      padding-top: 18px;
    }
  }
}

/* 页脚 */
.foot {
  width: 100%;
  background-color: #34476e;
}

.foot {
  padding-top: 16px;
  background: #34476e;
}

.foot_top_box {
  width: 1280px;
  min-width: 720px;
  margin: 0 auto;
  padding-bottom: 90px;
}

.foot_center_box {
  height: 168px;
  display: flex;

  margin-top: 61px;
  /* background-color: #000; */
}

.foot_center_left_box {
  margin-top: 61px;
}

.foot_center_box>ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.foot_center_box>ul>li {
  color: rgba(255, 255, 255, 0.56);
  cursor: pointer;
  font-size: 12px;
  line-height: 32px;
  margin: 0 193px 0 0;
}

.foot_center_bottom {
  height: 60px;
  margin-top: 150px;
  /* background-color: #fff; */
}

.foot_center_bottom_div {
  border-top: 2px solid rgba(255, 255, 255, 0.16);
  padding: 0 20px;
  padding-top: 18px;
}

/*  */


.headerText {
  height: 63%;
  /* width: 100%; */
  margin: 0 30px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  color: #211f1f;
}

.headerTextBorder {
  color: #c51722;
  border-bottom: 2px solid #c51722;
}

/*  */
.nav_sty {
  border-bottom: 2px solid #c51722 !important;
  color: #c51722;
}

.logo_img {
  height: 65px;
  line-height: 65px;
  cursor: pointer;
  padding-left: 100px;
}

#back_to_top {
  position: fixed;
  bottom: 300px;
  right: 30px;
  cursor: pointer;
}

.header-title {
  font-size: 1em;
  padding: 0 35px;
}

.company_name {
  font-weight: normal;
  font-size: 1em;
}

.footer_font {
  font-size: 1em;
}

#menu_index {
  width: 800px;
  height: 100%;
}

.header {
  display: flex;
  height: 100px;
  padding-left: 322px;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid #c51722;
  color: #c51722;
}


.el-icon-search::before {
  margin-right: 5px;
}

#header-img {
  width: 72px;
  float: left;
  margin-top: 24px;
}

.header-important {
  float: left;
  margin-left: 6px;
  align-self: center;
}

.footer {
  display: flex;
  align-items: center;
  margin: 10px auto;
  width: 90%;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.dianti {
  width: 60px;
  height: 60px;
  background: #fff;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

.M_dianti {
  width: 42px;
}

::v-deep .el-button:hover {
  background-color: #e23127;
  color: #fff;
  border-color: red;
}

::v-deep .el-input__inner:focus {
  background-color: #fef7f7;
  /* color: red; */
  border-color: #dcdfe6;
}

/* 移动端样式 */
.nav {
  display: flex;
  justify-content: space-between;
  padding: 0 21px;
}

.navImg {
  height: 58px;
}

.navIcon {
  width: 17px;
  height: 15px;
  margin-top: 25px;
}

.font {
  margin-top: 24px;
  text-align: center;
}

.en {
  /* font-family: PingFangSC-Regular, PingFang SC; */
  margin-bottom: 12px;
}

.zn {
  font-size: 31px;
  font-weight: 500;
  margin-bottom: 19px;
}

.anniu {
  margin: 0;
  padding: 0;
  width: 101px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  color: #fff;
  background: #e1251b;
}

.bofang {
  width: 22px;
  height: 22px;
  margin-top: 4px;
  line-height: 22px;
  background: #fff;
  color: red;
  z-index: 100;
  justify-content: right;
  border-radius: 16px;
  margin-right: 4px;
}

.twoHead {
  width: 100%;
}


.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.chanyefazhan {
  margin-top: 66px;
  /* background-color: pink; */
}

.chanyefazhan-head {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.henGang {
  height: 2px;
  width: 30px;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 25px;
  background-color: #ee0a24;
  border-bottom: 1px solid #e3bb58;
}

.chanyefazhan-font {
  font-family: "MaShanZheng-Regular", sans-serif;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 400;
}

.TenBtn {
  margin-top: 30px;
  height: 545px;
  position: relative;
  background-image: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/c589c1ab7e164dd2b53ad6e77fd51a2a.svg");
  background-color: #fff;
  /* background-color: #ee0a24; */
}

/* 缩宽  */
.BtnSty {
  padding: 0;
  width: 88px;
  height: 28px;
  /* 25px*/
  background: #ffffff;
  border-radius: 16px;
  color: #e1251b;
  border: 1px solid #e1251b;
  font-size: 10px;
  font-weight: 400;
}

.Ten {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.TenBtnL>div:nth-child(4n + 1) {
  margin: 25px 0;
  margin-left: 94px;
}

.TenBtnL>div:nth-child(2n) {
  margin-left: 40px;
}

.TenBtnL>div:nth-child(3) {
  margin: 50px 0 50px 20px;
}

.TenBtnR>div:nth-child(4n + 1) {
  margin: 25px 0;
  margin-right: 94px;
}

.TenBtnR>div:nth-child(2n) {
  margin-left: 54px;
}

.TenBtnR>div:nth-child(3) {
  margin: 50px 0 50px 70px;
}

.mapLogo {
  position: absolute;
  top: 110px;
  left: 130px;
  width: 123px;
  height: 115px;
}

.TenImg {
  height: 175px;
  margin-right: 8px;
}

.TenBtnFoot {
  height: 175px;
  padding: 16px;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.TenBtnFootFont {
  font-size: 9px;
  height: 138px;

  font-weight: 300;
  line-height: 14px;
}

.TenBtnFootFont>div {
  overflow: hidden;
  /* 超出容器范围的内容隐藏 */
  display: -webkit-box;
  -webkit-line-clamp: 10;
  /* 显示的行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
}

.TenBtnFootBtn {
  position: absolute;
  width: 80px;
}

.liaojiexiangqing {
  color: red;
  height: 24px;
  display: flex;
  font-size: 9px;
  font-weight: 400;
  margin-top: 10px;
}

.dian {
  width: 2px;
  height: 2px;
  position: absolute;
  background: red;
  top: 10px;
  left: 58px;
}

.journalism {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.journalismDian {
  width: 4px;
  height: 4px;
  border-radius: 25px;
  background: transparent;
  margin: 6px auto;
}

.journalismDianmove {
  background: #e3bb58;
}

.journalism>div:nth-child(2) {
  margin: 0 54px;
}

/* .journalismContent {
  height: 137px;
} */
.defaultImg {
  width: 100%;
  margin-top: 20px;
}

.name {
  width: 70px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: #ddb563;
  position: absolute;
  top: 10px;
  opacity: 0.8;
  padding: 6px 15px;
  margin-top: 10px;
}

.firstTitle {
  height: 32px;
  font-size: 10px;
  margin: 8px 0;
  font-weight: 500;
  overflow: hidden;
  /* 超出容器范围的内容隐藏 */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 显示的行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
}

.firstTime {
  font-size: 9px;
  font-weight: 400;
}

.hidden {
  overflow: hidden;
  /* 超出容器范围的内容隐藏 */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 显示的行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
}

.xinwen {
  background: #f8f8f8;
  padding: 0 16px;
  padding-bottom: 14px;
}

.journalismColumn {
  margin-top: 16px;
  display: flex;
}

.columnImg {
  width: 100%;
  margin-right: 13px;
}

.ColumnTitle {
  height: 27px;
  font-size: 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 14px;
  margin: 5px 0 20px 13px;
}

.ColumnTime {
  height: 11px;
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  margin-left: 13px;
  margin-bottom: 10px;
}

.ColumnBtn {
  width: 100%;
  margin-top: 12px;
  background-color: #f8f8f8;
}

.growUp {
  overflow: hidden;
  margin-bottom: 50px;
}

.growUpTitle {
  margin-top: 66px;
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: #181818;
  line-height: 28px;
  text-align: center;
}

.growUpIcon {
  width: 25px;
  height: 25px;
}

.moreYear {
  display: flex;
  transition: transform 0.3s ease;
}

.growUpYear {
  padding-left: 31px;
  width: 156px;
}

.year {
  height: 56px;
  font-size: 40px;
  font-weight: 600;
  color: #181818;
  line-height: 56px;
  margin-bottom: 20px;
}

.growUpContent {
  height: 144px;
  padding-right: 5px;
  border-right: 1px solid #000;
}

.growUpContentHidden {
  /* height: 15px; */
  font-size: 12px;
  font-weight: 400;
  color: #181818;
  /* line-height: 15px; */
  overflow: hidden;
  /* 超出容器范围的内容隐藏 */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* 显示的行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
}

.Mfoot {
  height: 180px;
  padding-top: 16px;
  background: #34476e;
}

.footTop {
  display: flex;
  margin: 0 16px 16px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  justify-content: space-between;
}

.footR {
  height: 60px;
  text-align: right;
  margin: 18px 10px 8px 8px;
  color: #fefefe;
}

.footRText {
  height: 16px;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.footRPhone {
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.erweimaText {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #e3bb58;
  line-height: 18px;
  text-align: center;
  margin-bottom: 16px;
}

.Search {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.searchErr {
  width: 32px;
  height: 32px;
  background: #fff !important;
  border-radius: 25px;
  margin-top: 0px;
  margin-left: 12px;
}

.showNav {
  display: flex;
  color: #fff;
  margin: 15px 16px 0 16px;
  justify-content: space-between;
}

::v-deep.van-search {
  padding: 0;
}

.unfoldContent>div {
  margin-bottom: 8px;
}

#M_back_to_top {
  position: fixed;
  bottom: 300px;
  right: 0px;
  cursor: pointer;
}

.iconTop {
  display: flex;
  justify-content: right;
  margin-top: 10px;
  color: #fff;
  margin-right: 20px;
}
</style>
